import styles from './ReportList.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useParams, Link } from 'react-router-dom';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import WarningIcon from '../../assets/images/warning.svg';
import toastCenter from '../../utils/toastCenter';
import Modal from '../../components/modal/ModalForm';
import Loader from '../../components/loader/Loader';
import { isMOHReportPending } from '../../services/reportAPI';
import { useSelector } from 'react-redux';
import { roleSelector, rolesSelector } from '../../store/user/selectors';
import { getIsUserEMRFacilityAdmin, isKHISLastDateExceeded } from './MOHReport';

interface IMatchParams {
  regionId: string;
  regionName: string;
  siteId: string;
  siteName: string;
}

const ReportList = (props: IMatchParams): React.ReactElement => {
  const [showAlertModal, setShowAlertModal] = useState({
    message: '',
    show: false
  });
  const [loading, setLoading] = useState(false);
  const { siteId, siteName, regionId, regionName } = useParams<IMatchParams>();
  const role: any = useSelector(roleSelector);

  const roles: any = useSelector(rolesSelector);

  const hasDuplicateProfileAccess = useCallback(() => {
    const allRoles = roles.map((role: any) => role.name);
    return APPCONSTANTS.DUPLICATE_PROFILE_ACCESS.some((role) => allRoles.includes(role));
  }, [roles]);

  useEffect(() => {
    toastCenter.dismissAllToast();
    const MOHStatusPayload = {
      key: getLastMonthKey().monthYear,
      reportType: 'MOH-740',
      siteId
    };
    if (
      getIsUserEMRFacilityAdmin(role) &&
      APPCONSTANTS.KENYA_ID === sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_CODE)
    ) {
      IsMOHReportPushedToKHIS(MOHStatusPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);
  const countryName = sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME);

  const reports = useMemo(
    () => [
      {
        name: 'Presc',
        label: 'Prescription Medication',
        route: PROTECTED_ROUTES.prescriptionReport,
        hasAccess: true
      },
      {
        name: 'ScreenedReport',
        label: 'Screened Patients',
        route: PROTECTED_ROUTES.screenedReport,
        hasAccess: true
      },
      {
        name: 'EnrolledPatientReport',
        label: 'Enrolled Patients',
        route: PROTECTED_ROUTES.enrolledPatientReport,
        hasAccess: true
      },
      {
        name: 'AssessmentReport',
        label: 'Assessment Report',
        route: PROTECTED_ROUTES.assessmentReport,
        hasAccess: true
      },
      {
        name: 'UserPerformance',
        label: 'User Performance',
        route: PROTECTED_ROUTES.userPerformance,
        hasAccess: true
      },
      {
        name: 'PatientStatusReport',
        label: 'Patient-Status Report',
        route: PROTECTED_ROUTES.patientStatusReport,
        hasAccess: true
      },
      {
        name: 'PatientDuplicateReport',
        label: 'Duplicate Profiles',
        route: PROTECTED_ROUTES.patientDuplicateReport,
        hasAccess: hasDuplicateProfileAccess()
      }
    ],
    [hasDuplicateProfileAccess]
  );
  if (
    countryName === APPCONSTANTS.KENYA ||
    sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_CODE) === APPCONSTANTS.KENYA_ID
  ) {
    reports.unshift(
      {
        name: 'Daily',
        label: 'Daily-Activity-Register MOH-222',
        route: PROTECTED_ROUTES.dailyReport,
        hasAccess: true
      },
      {
        name: 'Permanent',
        label: 'Permanent-Register MOH-270',
        route: PROTECTED_ROUTES.permanentReport,
        hasAccess: true
      },
      {
        name: 'MOH',
        label: 'Monthly-Summary-Form MOH-740',
        route: PROTECTED_ROUTES.MOHReport,
        hasAccess: true
      }
    );
  }

  const getLastMonthKey = () => {
    const now = new Date();
    const newdate = new Date(now.setMonth(now.getMonth() - 1));
    const key = {
      month: '',
      monthYear: ''
    };
    key.month = APPCONSTANTS.MONTH[newdate.getMonth()].name;
    key.monthYear = APPCONSTANTS.MONTH[newdate.getMonth()].label + new Date().getFullYear();
    return key;
  };

  const IsMOHReportPushedToKHIS = useCallback(async (MOHStatusPayload) => {
    try {
      setLoading(true);
      const { data } = await isMOHReportPending(MOHStatusPayload);
      setLoading(false);
      if (!data?.entity?.is_data_pushedTo_KHIS && !isKHISLastDateExceeded()) {
        setShowAlertModal({
          message: `This site has pending reports of ${getLastMonthKey().month} to be sent to KHIS`,
          show: true
        });
      }
    } catch (error: any) {
      setLoading(false);
      setShowAlertModal({ message: error.message, show: true });
    }
  }, []);

  const alertModalRender = () => {
    return (
      <div className={styles.alertItems}>
        <img className={styles.alertModalIcon} src={WarningIcon} alt='Alert' />
        <br />
        <p className={styles.alertModalText}>{showAlertModal.message}</p>
        <br />
        <button
          className={styles.closeButton}
          onClick={() => {
            setShowAlertModal({
              message: '',
              show: false
            });
          }}
        >
          OK
        </button>
      </div>
    );
  };

  const parsedData = useMemo(
    () =>
      reports
        .filter(({ hasAccess }) => hasAccess)
        .map(({ label, route, name }) => ({
          title: label,
          detailRoute: route.replace(':regionId', regionId).replace(':regionName', regionName).replace(':siteId', siteId).replace(':siteName', siteName),
          _id: countryId,
          icon: require(`../../assets/images/${name}.svg`).default,
          name: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME)
        })),
    [regionId, regionName, reports, siteId, siteName, countryId]
  );

  return (
    <>
      <div className={`position-relative ${styles.regionContainer}`}>
        <div className='row'>
          {loading && <Loader />}
          {parsedData.map((data) => (
            <div className='col-3 p-1 width-20' key={data.title}>
              <div className='cards'>
                <Link to={data.detailRoute}>
                  <div className='row p-2'>
                    <img src={data.icon} alt={`${data.title}_logo`} width={60} height={70} />
                  </div>
                  <div className={`row ${styles.report_card_text} py-1`}>
                    <p>{data.title.toUpperCase()}</p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showAlertModal.show}
        isAlertModal={true}
        title=''
        size='modal-md'
        autoHeight={true}
        hasFooter={false}
        // tslint:disable-next-line:no-empty
        handleFormSubmit={() => {}}
        render={alertModalRender}
      />
    </>
  );
};

export default ReportList;
