import Spreadsheet from 'x-data-spreadsheet';
import { useEffect, useRef, useState } from 'react';
import XLSX, { WorkBook } from 'xlsx';
import APPCONSTANTS from '../../constants/appConstants';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import Loader from '../loader/Loader';
import './SpreadSheetViewer.scss';

interface ISpreadSheetViewerProps {
  isEdit?: boolean;
  data: any;
  className?: string;
  isMultiSheet?: boolean;
}

const formatData = (workbook: WorkBook) => {
  const formattedData: any = [];
  workbook.SheetNames.forEach((name: any) => {
    const sheetRow: any = { name, rows: {} };
    const workbookSheet = workbook.Sheets[name];
    const sheetData = XLSX.utils.sheet_to_json(workbookSheet, { raw: false, header: 1 });
    sheetData.forEach((row: any, rowIndex: any) => {
      const cells: any = {};
      row.forEach((cell: any, cellIndex: any) => {
        cells[cellIndex] = { text: cell };
      });
      sheetRow.rows[rowIndex] = {
        cells
      };
    });
    formattedData.push(sheetRow);
  });
  return formattedData;
};

const SpreadsheetViewer = ({ data, isEdit = false, className = '', isMultiSheet = true }: ISpreadSheetViewerProps) => {
  const spreadSheetContainer = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const workbook = XLSX.read(data, { type: 'array', cellFormula: true, cellStyles: true });
        const result = formatData(workbook);
        const keys = Object.keys(result[0]?.rows);
        const columns = Object.keys(result[0]?.rows[0].cells);
        const spreadsheet = new Spreadsheet(spreadSheetContainer.current as HTMLDivElement, {
          mode: isEdit ? 'edit' : 'read',
          showToolbar: isEdit,
          showBottomBar: isMultiSheet,
          showContextmenu: false,
          row: {
            len: keys.length >= 100 ? keys.length : 100,
            height: 25
          },
          col: {
            len: columns.length >= 26 ? columns.length : 26,
            width: 100,
            indexWidth: 60,
            minWidth: 60
          },
          view: {
            height: () => document.documentElement.clientHeight - 200 || 400,
            width: () => document.documentElement.clientWidth - 100 || 500
          }
        });
        spreadsheet.loadData(result);
      } catch (e) {
        setLoading(false);
        setIsError(true);
        return;
      }
      setLoading(false);
    })();
  }, [data, isEdit, isMultiSheet]);

  return (
    <>
      <div ref={spreadSheetContainer} className={className}>
        {isError && APPCONSTANTS.REPORT_NOT_FOUND}
      </div>
      {loading && <Loader />}
    </>
  );
};

export default SpreadsheetViewer;
