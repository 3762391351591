import { all, fork } from 'redux-saga/effects';

import userSaga from './user/sagas';
import regionSaga from './region/sagas';
import siteSaga from './site/sagas';
import reportSaga from './report/sagas';
import userRolesSaga from './userRoles/sagas';
import profileReviewSaga from './duplicateProfiles/sagas';

export function* rootSaga() {
  yield all([fork(userSaga)]);
  yield all([fork(regionSaga)]);
  yield all([fork(siteSaga)]);
  yield all([fork(reportSaga)]);
  yield all([fork(profileReviewSaga)]);
  yield all([fork(userRolesSaga)]);
}
