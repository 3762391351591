import React from 'react';

import EditIcon from '../../assets/images/edit.svg';
import styles from './CustomTable.module.scss';
import Loader from '../loader/Loader';
import DeleteIcon from '../../assets/images/bin.svg';
import ViewIcon from '../../assets/images/showPass.svg';
import DownloadIcon from '../../assets/images/download.svg';
import SuccessIcon from '../../assets/images/success-green.svg';
import ErrorIcon from '../../assets/images/Failed.svg';
import APPCONSTANTS from '../../constants/appConstants';
import Pagination from '../Pagination';
import CustomTooltip from '../tooltip';
export interface IAnyObject {
  [key: string]: any;
}
export interface ICustomProps {
  title: string | ((data: any) => string);
  icon: string | ((data: any) => string);
  style: any;
  isSendButtonEnabled?: any;
  isMOHReport?: any;
  onClick?: (event: any, data: any, index: any) => void;
  tooltipFormatter?: (data: any) => string;
  iconFormatter?: any;
  type?: (data: any) => string;
}
interface ICustomTableProps {
  handlePageChange?: (pageNo: number, rowsPerPage?: number) => void;
  columnsDef: IColumns[];
  rowData: any;
  isEdit: boolean;
  isDelete: boolean;
  isView: boolean;
  isDownload: boolean;
  page?: number;
  count?: number;
  isBordered?: boolean;
  isRowEdit?: boolean;
  isStatus?: boolean;
  onRowEdit?: (data: any) => void;
  onRowView?: (data: any) => void;
  onRowDownload?: (data: any) => void;
  confirmationTitle?: string;
  loading?: boolean;
  onDeleteClick?: (deleteData: {
    data: any;
    index: number;
    pageNo: number;
  }) => void;
  className?: string;
  isSearching?: boolean;
  noDataText?: string;
  orderBy?: string;
  order?: number;
  rowsPerPage?: number;
  deleteTitle?: string;
  handleRowClick?: (data: IAnyObject) => void;
  isCustom?: boolean;
  customProps?: ICustomProps[];
  customStatusProps?: ICustomProps[];
}

export interface IColumns {
  id: number;
  name: string;
  label: string;
  width?: string;
  fontSize?: string;
  cellFormatter?: (data: any, column: IColumns) => void;
  cellStyleFormatter?: (data: any, isBordered?: boolean) => string;
  disableSort?: boolean;
  align?: 'center' | 'left';
  class?: string;
}

interface ICustomTableState {
  openDialog: boolean;
}

export default class CustomTable extends React.PureComponent<
  ICustomTableProps,
  ICustomTableState
> {
  tableRef: React.RefObject<HTMLInputElement>;
  constructor(props: ICustomTableProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDialog: false,
    };
  }

  /**
   * Handle pagination change of custom table
   * @param e Mouse event for onchange
   * @param pageNo Current page number
   * @param rowsPerPage Row count per page
   */
  handlePageChange = (pageNo: number, rowsPerPage: number) => {
    if (this.tableRef && this.tableRef.current) {
      this.tableRef.current.scrollTo(0, 0);
    }
    if (this.props.handlePageChange) {
      this.props.handlePageChange(pageNo, rowsPerPage);
    }
  };

  /**
   * Handle delete of custom table
   * @param data Data to delete
   * @param index Index of data
   * @param pageNo Current page number
   */
  handleDelete = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: IAnyObject,
    index: number,
    pageNo?: number | undefined
  ) => {
    e.stopPropagation();
    this.setState({ openDialog: true });
  };

  handleEdit = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: any,
    index: number
  ) => {
    e.stopPropagation();
    if (this.props.onRowEdit) {
      this.props.onRowEdit({ ...data, index });
    }
  };

  handleView = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: any,
    index: number
  ) => {
    e.stopPropagation();
    if (this.props.onRowView) {
      this.props.onRowView({ ...data, index });
    }
  };

  handleDownload = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: any,
    index: number
  ) => {
    e.stopPropagation();
    if (this.props.onRowDownload) {
      this.props.onRowDownload({ ...data, index });
    }
  };

  handleChangeRowsPerPage = (rowsPerPage: number) => {
    if (this.tableRef && this.tableRef.current) {
      this.tableRef.current.scrollTo(0, 0);
    }
    if (this.props.handlePageChange) {
      this.props.handlePageChange(1, Number(rowsPerPage));
    }
  };

  navigateToDetail = (data: IAnyObject) => {
    if (this.props.handleRowClick) {
      this.props.handleRowClick(data);
    }
  };

  handleShowPagination = () => {
    const { page, rowsPerPage, count } = this.props;
    return page &&
      rowsPerPage &&
      count &&
      count > APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE ? (
      <div className={styles.paginationWrapper}>
        <Pagination
          initialPage={page}
          total={count}
          length={rowsPerPage}
          currentPage={page}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    ) : null;
  };

  handleShowColumnHeaders = (columnsDef: IColumns[]) => {
    return (
      columnsDef &&
      columnsDef.map((column: IColumns, index: number) => (
        <th key={index} style={{ width: column.width, fontSize: column.fontSize }}>
          {column.label}
        </th>
      ))
    );
  };

  handleShowActionHeader = (actions: boolean) => {
    return (
      actions && (
        <th className='text-center' style={{ width: '80px' }}>
          Actions
        </th>
      )
    );
  };

  handleShowStatusHeader = (isStatus: boolean | undefined) => {
    return (
      isStatus && (
        <th className='text-center' style={{ width: '100px' }}>
          Status
        </th>
      )
    );
  };

  handleCursorPointerStyle = () => {
    const { handleRowClick, isRowEdit } = this.props;
    return handleRowClick || isRowEdit ? 'pointer' : '';
  };

  handleShowEditIcon = (data: IAnyObject, idx: number) => {
    const { isEdit } = this.props;
    return (
      isEdit && (
        <div
          className={styles.editIcon}
          onClick={(e) => this.handleEdit(e, data, idx)}
        >
          <CustomTooltip title='Edit'>
            <img src={EditIcon} alt='edit-icon' />
          </CustomTooltip>
        </div>
      )
    );
  };

  handleShowDeleteIcon = (data: IAnyObject, idx: number) => {
    const { isDelete } = this.props;
    return (
      isDelete && (
        <div
          className={styles.deleteIcon}
          onClick={(e) => this.handleDelete(e, data, idx)}
        >
          <CustomTooltip title='Delete'>
            <img src={DeleteIcon} alt='delete-icon' />
          </CustomTooltip>
        </div>
      )
    );
  };

  handleShowDownloadIcon = (data: IAnyObject, idx: number) => {
    const { isDownload } = this.props;
    return (
      isDownload && (
        <div
          className={styles.downloadIcon}
          onClick={(e) => this.handleDownload(e, data, idx)}
        >
          <CustomTooltip title='Download'>
            <img src={DownloadIcon} alt='download-icon' />
          </CustomTooltip>
        </div>
      )
    );
  };

  handleShowViewIcon = (data: IAnyObject, idx: number) => {
    const { isView } = this.props;
    return (
      isView && (
        <div
          className={styles.viewIcon}
          onClick={(e) => this.handleView(e, data, idx)}
        >
          <CustomTooltip title='View'>
            <img src={ViewIcon} alt='view-icon' />
          </CustomTooltip>
        </div>
      )
    );
  };

  handleStatus = (data: any, customStatusProps: any) => {
    const { isStatus, isCustom } = this.props;
    return (
      isStatus && (
        <td className='text-center px-2'>
          <div className='d-flex justify-content-between align-items-center'>
            {isCustom &&
              customStatusProps.map((customProp: any, idx: any) => (
                <div
                  key={idx}
                  className={
                    customProp.onClick
                      ? styles.customIcon
                      : styles.customStatusIcon
                  }
                  onClick={(e) =>
                    customProp?.onClick && customProp?.onClick(e, data, idx)
                  }
                >
                  <CustomTooltip
                    title={
                      (customProp?.tooltipFormatter &&
                        customProp?.tooltipFormatter(data)) ||
                      (typeof customProp?.title === 'string'
                        ? customProp?.title
                        : customProp?.title(data))
                    }
                  >
                    <img
                      src={
                        (customProp?.iconFormatter &&
                          (customProp?.iconFormatter(data) ===
                            APPCONSTANTS.SUCCESS.toUpperCase() ||
                            customProp?.iconFormatter(data) ===
                              APPCONSTANTS.ERROR.toUpperCase() ||
                            customProp?.iconFormatter(data) ===
                              APPCONSTANTS.WARNING.toUpperCase()) &&
                          (customProp?.iconFormatter(data) ===
                          APPCONSTANTS.SUCCESS.toUpperCase()
                            ? SuccessIcon
                            : ErrorIcon)) ||
                        customProp?.icon
                      }
                      style={
                        typeof customProp?.style === 'object'
                          ? customProp?.style
                          : customProp?.style(data)
                      }
                      alt='custom-icon'
                    />
                  </CustomTooltip>
                </div>
              ))}
          </div>
        </td>
      )
    );
  };

  renderTableHeader = (
    columnsDef: IColumns[],
    actions: boolean,
    isStatus: boolean | undefined
  ) => {
    return (
      <thead>
        <tr>
          {this.handleShowColumnHeaders(columnsDef)}
          {this.handleShowActionHeader(actions)}
          {this.handleShowStatusHeader(isStatus)}
        </tr>
      </thead>
    );
  };

  handleCustomProps = (customProps: any, data: any) => {
    const { isCustom } = this.props;
    return (
      isCustom &&
      customProps.map((customProp: any, idx: number) => {
        const isMOHReport = customProp.isMOHReport;
        const isSendButtonEnabled =
          isMOHReport && customProp.isSendButtonEnabled(data);
        const hasCustomIcon = isMOHReport
          ? isSendButtonEnabled
          : customProp.onClick !== undefined;
        const isCustomSuccessIcon =
          customProp?.type && customProp?.type(data) === 'success';

        return (
          <div
            key={idx}
            className={`${
              hasCustomIcon ? styles.customIcon : styles.customNoHoverIcon
            } ${isCustomSuccessIcon && styles.customSuccessIcon}`}
            onClick={(e) =>
              customProp?.onClick && customProp?.onClick(e, data, idx)
            }
          >
            <CustomTooltip
              title={
                (customProp?.tooltipFormatter &&
                  customProp?.tooltipFormatter(data)) ||
                (typeof customProp?.title === 'string'
                  ? customProp?.title
                  : customProp?.title(data))
              }
            >
              <img
                src={
                  customProp?.iconFormatter
                    ? customProp?.iconFormatter(data)
                    : typeof customProp?.icon === 'string'
                    ? customProp?.icon
                    : customProp?.icon(data)
                }
                style={
                  typeof customProp?.style === 'object'
                    ? customProp?.style
                    : customProp?.style(data)
                }
                alt='custom-icon'
              />
            </CustomTooltip>
          </div>
        );
      })
    );
  };

  render() {
    const {
      columnsDef,
      rowData,
      isEdit,
      isDelete,
      isStatus,
      isView,
      isDownload,
      loading,
      isBordered,
      isCustom = false,
      customProps = [
        {
          title: '',
          icon: '',
          style: {},
          // tslint:disable-next-line:no-empty
          onClick: () => {},
          tooltipFormatter: () => '',
          // tslint:disable-next-line:no-empty
          iconFormatter: () => {},
          isMOHReport: false,
          isSendButtonEnabled: false,
          // tslint:disable-next-line:no-empty
          type: () => {},
        },
      ],
      customStatusProps = [
        {
          title: '',
          icon: '',
          style: {},
          // tslint:disable-next-line:no-empty
          onClick: () => {},
          tooltipFormatter: () => '',
          // tslint:disable-next-line:no-empty
          iconFormatter: () => {},
          isMOHReport: false,
          isSendButtonEnabled: false,
          // tslint:disable-next-line:no-empty
          type: () => {},
        },
      ],
    } = this.props;
    const actions = isEdit || isDelete || isView || isDownload || isCustom;

    return (
      <div className={styles.customTable}>
        <table>
          {this.renderTableHeader(columnsDef, actions, isStatus)}
          <tbody className={this.handleCursorPointerStyle()}>
            {rowData.length && !loading ? (
              <>
                {rowData.map((data: IAnyObject, idx: number) => (
                  <tr key={idx} onClick={() => this.navigateToDetail(data)}>
                    {columnsDef &&
                      columnsDef.map((column: IColumns) => (
                        <td
                          key={column.id}
                          className={
                            (column.class ? 'numeric' : '') +
                            column.cellStyleFormatter?.(data, isBordered)
                          }
                        >
                          <span className={column.cellStyleFormatter?.(data)}>
                            {column.cellFormatter
                              ? column.cellFormatter(data, column)
                              : data[column.name]}
                          </span>
                        </td>
                      ))}
                    {actions && (
                      <td key={idx} className='text-center'>
                        <div className='d-inline-flex'>
                          {this.handleShowEditIcon(data, idx)}
                          {this.handleShowViewIcon(data, idx)}
                          {this.handleShowDownloadIcon(data, idx)}
                          {this.handleShowDeleteIcon(data, idx)}
                          {this.handleCustomProps(customProps, data)}
                        </div>
                      </td>
                    )}

                    {this.handleStatus(data, customStatusProps)}
                  </tr>
                ))}
              </>
            ) : (
              <tr className='cursor-default'>
                <td
                  colSpan={actions ? columnsDef.length + 1 : columnsDef.length}
                >
                  {loading && <Loader isFulllScreen={false} />}
                  {!loading && (
                    <div className='text-center'>
                      {APPCONSTANTS.NO_RECORDS_FOUND}
                    </div>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.handleShowPagination()}
      </div>
    );
  }
}
