import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as regionService from '../../services/regionAPI';
import { IFetchFileURL, IFetchRegionsRequest } from './types';
import { fetchFileURLFailure, fetchFileURLSuccess, fetchRegionsFailure, fetchRegionsSuccess } from './actions';
import { FETCH_FILE_URL_REQUEST, FETCH_REGIONS_REQUEST } from './actionTypes';
/*
  Worker Saga: Fired on FETCH_REGIONS_REQUEST action
*/
export function* fetchRegions({
  isLoadMore,
  skip,
  limit,
  search,
  successCb,
  failureCb
}: IFetchRegionsRequest): SagaIterator {
  try {
    const {
      data: { entityList: regions, totalCount }
    } = yield call(regionService.fetchRegions as any, limit, skip, undefined, search);
    const payload = { regions, totalCount, isLoadMore };
    successCb?.(payload);
    yield put(fetchRegionsSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(fetchRegionsFailure(e));
    }
  }
}

export function* fetchFileURL({ failureCb, successCb }: IFetchFileURL): SagaIterator {
  try {
    const response = yield call(regionService.getFileURL as any);
    yield put(fetchFileURLSuccess());
    successCb(response.data);
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(fetchFileURLFailure());
    }
  }
}

/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* regionSaga() {
  yield all([takeLatest(FETCH_REGIONS_REQUEST, fetchRegions)]);
  yield all([takeLatest(FETCH_FILE_URL_REQUEST, fetchFileURL)]);
}

export default regionSaga;
