import axios from 'axios';

export const login = (username: string, password: string) => {
  const loginData = new FormData();
  loginData.append('username', username);
  loginData.append('password', password);
  return axios({
    method: 'POST',
    url: 'auth-service/session',
    data: loginData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const logout = () =>
  axios({
    method: 'GET',
    url: 'auth-service/logout'
  });

export const forgotPassword = (username: string) =>
  axios({
    method: 'GET',
    url: `user-service/user/forgot-password/${username}/cfr`
  });

export const resetPasswordReq = (data: { email: string; password: string }, token: string) =>
  axios({
    method: 'POST',
    url: `user-service/user/reset-password/${token}`,
    data: {
      ...data
    }
  });

export const getUsername = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/verify-set-password/${token}`
  });

export const createPassword = (data: { password: string }, token: string) =>
  axios({
    method: 'post',
    url: `user-service/user/set-password/${token}`,
    data: {
      password: data.password
    }
  });

export const fetchTimezoneList = () =>
  axios({
    method: 'GET',
    url: '/forms/timezone?$limit=null'
  });

export const fetchUserRoles = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/get-user-roles?$token=${token}`
  });

export const fetchLoggedInUser = () =>
  axios({
    method: 'get',
    url: `user-service/user/logged-in-user`
  });

export const getDefaultRoles = () =>
  axios({
    method: 'get',
    url: 'user-service/user/roles'
  });

export const fetchTermsConditionsAPI = (countryId: number) =>
  axios({
    method: 'POST',
    url: '/admin-service/terms-and-condition/details',
    data: { countryId }
  });

export const updateTermsConditionsAPI = ({
  userId,
  isTermsAndConditionAccepted
}: {
  userId: number;
  isTermsAndConditionAccepted: boolean;
}) =>
  axios({
    method: 'POST',
    url: '/user-service/user/terms-and-condition/update',
    data: { userId, isTermsAndConditionAccepted }
  });
