import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import APPCONSTANTS from '../../constants/appConstants';
import { changeAdminUserRole, fetchUserRolesList, fetchSiteAdminSites } from '../../services/userRolesAPI';
import {
  fetchUserRolesListFailure,
  fetchUserRolesListSuccess,
  changeAdminUserRoleFailure,
  changeAdminUserRoleSuccess,
  fetchSiteAdminSitesFailure,
  fetchSiteAdminSitesSuccess
} from './actions';
import {
  CHANGE_ADMIN_USER_ROLE_REQUEST,
  FETCH_SITE_ADMIN_SITES_REQUEST,
  FETCH_USER_ROLES_LIST_REQUEST
} from './actionTypes';
import {
  IChangeAdminUserRoleRequest,
  IFetchUserRolesListRequest,
  IUserRolesList,
  IFetchSiteAdminSitesRequest,
  ISiteAdminSites
} from './types';

export function* fetchUserRolesListRequest({ skip, limit, search, country, failureCb }:
  IFetchUserRolesListRequest): SagaIterator {
  try {
    const { data: { entityList: userRolesList, totalCount } } =
      yield call(fetchUserRolesList, { limit, skip, search, country } as IFetchUserRolesListRequest);

    const getUserRolesByAppName = (applicationName: string) => {
      switch (applicationName) {
        case 'country': {
          return APPCONSTANTS.ROLES.REGION_ADMIN;
        }
        case 'site': {
          return APPCONSTANTS.ROLES.SITE_ADMIN;
        }
        case 'account': {
          return APPCONSTANTS.ROLES.ACCOUNT_ADMIN;
        }
        case 'operatingunit': {
          return APPCONSTANTS.ROLES.OU_ADMIN;
        }
        default: {
          return '';
        }
      }
    };
    const getApplicationName = (applicationName: string) => {
      switch (applicationName) {
        case APPCONSTANTS.APPLICATION_NAME.COUNTRY.NAME:
          return APPCONSTANTS.APPLICATION_NAME.COUNTRY.LABEl;
        case APPCONSTANTS.APPLICATION_NAME.ACCOUNT.NAME:
          return APPCONSTANTS.APPLICATION_NAME.ACCOUNT.LABEL;
        case APPCONSTANTS.APPLICATION_NAME.OPERATING_UNIT.NAME:
          return APPCONSTANTS.APPLICATION_NAME.OPERATING_UNIT.LABEL;
        case APPCONSTANTS.APPLICATION_NAME.SITE.NAME:
          return APPCONSTANTS.APPLICATION_NAME.SITE.LABEL;
      }
    };
    const formattedUserRolesList: IUserRolesList[] = userRolesList.map((data: any) => ({
      id: data?.id,
      name: data?.firstName && data?.lastName ? `${data?.firstName}  ${data?.lastName}` : `${data?.firstName}`,
      email: data?.email,
      role: getUserRolesByAppName(data?.applicationName),
      org_name: data?.orgName,
      isAssigned: data?.isAssigned,
      isEmrUserSiteAdmin: data?.isEmrUserSiteAdmin,
      isQuicksightSpiceUser: data?.isQuicksightSpiceUser,
      isQuicksightTcUser: data?.isQuicksightTcUser,
      application_name: getApplicationName(data?.applicationName)
    }));
    const payload = { userRolesList: formattedUserRolesList, totalCount, limit };
    yield put(fetchUserRolesListSuccess(payload));
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(fetchUserRolesListFailure(e));
    }
  }
}

export function* changeAdminUserRoleRequest({ data, successCb, failureCb }: IChangeAdminUserRoleRequest): SagaIterator {
  try {
    yield call(changeAdminUserRole, data);
    yield put(changeAdminUserRoleSuccess());
    successCb?.();
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(changeAdminUserRoleFailure(e));
    }
  }
}

export function* fetchSiteAdminSitesRequest({ id, successCb, failureCb }:
  IFetchSiteAdminSitesRequest): SagaIterator {
  try {
    const { data: { entityList: sites } } = yield call(fetchSiteAdminSites, { id } as IFetchSiteAdminSitesRequest);
    const siteAdminSites: ISiteAdminSites[] = sites.map((data: any) => ({
      value: data?.tenantId,
      label: data?.name,
      is_assigned: data?.isAssigned,
      is_default: data?.isDefault
    }));
    yield put(fetchSiteAdminSitesSuccess({ siteAdminSites }));
    successCb?.(siteAdminSites);
  } catch (e) {
    if (e instanceof Error) {
      failureCb?.(e);
      yield put(fetchSiteAdminSitesFailure(e));
    }
  }
}

/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* userRolesSaga() {
  yield all([takeLatest(FETCH_USER_ROLES_LIST_REQUEST, fetchUserRolesListRequest)]);
  yield all([takeLatest(CHANGE_ADMIN_USER_ROLE_REQUEST, changeAdminUserRoleRequest)]);
  yield all([takeLatest(FETCH_SITE_ADMIN_SITES_REQUEST, fetchSiteAdminSitesRequest)]);
}

export default userRolesSaga;
