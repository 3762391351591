import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import crypto from 'crypto';

import * as userActions from './actions';
import { IActionProps } from '../../typings/global';
import * as USERTYPES from './actionTypes';
import {
  IFetchLoggedInUserRequest,
  IFetchTermsConditionsRequest,
  ILoginRequest,
  IUpdateTermsConditionsRequest,
  IUser,
  roleType
} from './types';
import * as userService from '../../services/userAPI';
import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import localStorageServices from '../../global/localStorageServices';
import { encryptData } from '../../utils/commonUtils';
import { success, error, info } from '../../utils/toastCenter';

/*
  Worker Saga: Fired on LOGIN_REQUEST action
*/
export function* login({ username, password, rememberMe, successCb, failureCb }: ILoginRequest): SagaIterator {
  try {
    const hmac = crypto.createHmac(APPCONSTANTS.HASH_ALGORITM, process.env.REACT_APP_PASSWORD_HASH_KEY as string);
    hmac.update(password);
    const hashedPassword = hmac.digest('hex');
    const {
      headers: { authorization },
      country,
      data: { isTermsAndConditionAccepted }
    } = yield call(userService.login, username, hashedPassword);
    sessionStorageServices.setItem(APPCONSTANTS.AUTHTOKEN, authorization);
    localStorageServices.setItem(APPCONSTANTS.TAC_STATUS, isTermsAndConditionAccepted);
    localStorageServices.setItem(APPCONSTANTS.IS_TERMS_CONDITIONS_DISMISSED, false);
    const {
      data: {
        entity: { firstName, lastName, id, tenantId, countryId, countryName, countryCode }
      }
    } = yield call(userService.fetchLoggedInUser);
    sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_ID, countryId?.id || countryId);
    const {
      data: { entityList: roles }
    } = yield call(userService.fetchUserRoles, id);
    let filteredRoles = roles.filter(
      (role: any) =>
        role.name === APPCONSTANTS.ROLES.EMR_REPORT_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.SYSTEM_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_SITE_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_SITE_USER.role ||
        role.name === APPCONSTANTS.ROLES.EMR_REGION_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_ACCOUNT_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_OU_ADMIN.role
    );
    filteredRoles = filteredRoles.length
      ? filteredRoles
      : roles.filter(
          (role: any) =>
            role.name === APPCONSTANTS.ROLES.EMR_QUICKSIGHT_SPICE_ADMIN.role ||
            role.name === APPCONSTANTS.ROLES.EMR_QUICKSIGHT_TC_ADMIN.role
        );
    const userRole: roleType[] =
      filteredRoles?.length && Object.values(APPCONSTANTS.ROLES).filter(({ role }) => role === filteredRoles[0].name);
    if (
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_REPORT_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.SYSTEM_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_SITE_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_SITE_USER.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_REGION_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_ACCOUNT_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_OU_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_QUICKSIGHT_SPICE_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_QUICKSIGHT_TC_ADMIN.role
    ) {
      info(APPCONSTANTS.ALERT, APPCONSTANTS.UNAUTHORIZED_ACCESS_MESSAGE);
      yield put(userActions.logoutRequest());
    } else {
      sessionStorageServices.setItem(APPCONSTANTS.TENANT_ID, tenantId);
      sessionStorageServices.setItem(APPCONSTANTS.REGION_ID, countryId);
      sessionStorageServices.setItem(APPCONSTANTS.REGION_NAME, countryName);
      sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_CODE, countryCode);
      updateRememberMe(username, password, rememberMe);
      const payload = {
        username,
        firstName,
        lastName,
        id,
        role: userRole[0],
        tenantId,
        country: country || countryId,
        countryName,
        roles
      };
      successCb?.(payload);
      yield put(userActions.loginSuccess(payload));
    }
  } catch (e: any) {
    failureCb?.(e);
    yield put(userActions.loginFailure({ error: e.message }));
  }
}

export function* logout(): SagaIterator {
  try {
    yield call(userService.logout);
    sessionStorageServices.clearAllItem();
    yield put(userActions.logoutSuccess());
    yield put(userActions.resetStore());
  } catch (e) {
    sessionStorageServices.deleteItem(APPCONSTANTS.AUTHTOKEN);
    yield put(userActions.logoutFailure());
  }
}

function updateRememberMe(username: string, password: string, rememberMe: boolean) {
  try {
    if (rememberMe) {
      localStorageServices.setItems([
        { key: APPCONSTANTS.USERNAME, value: username },
        { key: APPCONSTANTS.PASSWORD, value: encryptData(password) },
        { key: APPCONSTANTS.REMEMBER_ME, value: rememberMe }
      ]);
    } else {
      localStorageServices.deleteItems([APPCONSTANTS.USERNAME, APPCONSTANTS.PASSWORD, APPCONSTANTS.REMEMBER_ME]);
    }
  } catch (e) {
    console.error('Error occured', e);
  }
}

export function* userForgotPassword(action: any) {
  const { email, successCB } = action;
  try {
    yield call(userService.forgotPassword, email.toLowerCase());
    yield put(userActions.forgotPasswordSuccess());
    success(APPCONSTANTS.SUCCESS, APPCONSTANTS.PASSWORD_RESET_EMAIL_SENT_MESSAGE);
    successCB();
  } catch (e: any) {
    error(APPCONSTANTS.ALERT, e.message);
    yield put(userActions.forgotPasswordFail(e));
  }
}

export function* resetPassword(action: IActionProps) {
  const { email, password, token, successCB } = action.data;
  try {
    yield call(userService.resetPasswordReq, { email, password }, token);
    yield put(userActions.resetPasswordSuccess());
    success(APPCONSTANTS.SUCCESS, APPCONSTANTS.PASSWORD_SET_SUCCESS);
    successCB();
  } catch (e: any) {
    yield put(userActions.resetPasswordFail(e));
    const message = e?.message || APPCONSTANTS.PASSWORD_SET_FAILED;
    error(APPCONSTANTS.ALERT, message);
  }
}

export function* getUsername(action: IActionProps): SagaIterator {
  const { token, successCB, failureCB } = action;
  try {
    const userData = yield call(userService.getUsername, token);
    yield put(userActions.getUserNameSuccess(userData.data.entity));
    successCB();
  } catch (e) {
    failureCB();
    yield put(userActions.getUserNameFail(e));
  }
}

export function* createPassword(action: IActionProps) {
  const { data, token, successCB } = action;
  try {
    yield call(userService.createPassword, data, token);
    yield put(userActions.createPasswordSuccess());
    success(APPCONSTANTS.SUCCESS, APPCONSTANTS.PASSWORD_SET_SUCCESS);
    successCB();
  } catch (e: any) {
    yield put(userActions.createpasswordFail(e.message));
    error(APPCONSTANTS.ALERT, APPCONSTANTS.PASSWORD_SET_FAILED);
  }
}

export function* fetchTimezoneList(): SagaIterator {
  try {
    const {
      data: { data: timezoneList }
    } = yield call(userService.fetchTimezoneList);
    yield put(userActions.fetchTimezoneListSuccess(timezoneList));
  } catch (e) {
    yield put(userActions.fetchTimezoneListFailure());
  }
}

export function* fetchLoggedInUser(action: IFetchLoggedInUserRequest): SagaIterator {
  try {
    const {
      data: {
        entity: { username, firstName, lastName, id, tenantId }
      }
    } = yield call(userService.fetchLoggedInUser);
    const {
      data: { entityList: roles }
    } = yield call(userService.fetchUserRoles, id);

    let filteredRoles = roles.filter(
      (role: any) =>
        role.name === APPCONSTANTS.ROLES.EMR_REPORT_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.SYSTEM_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_SITE_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_SITE_USER.role ||
        role.name === APPCONSTANTS.ROLES.EMR_REGION_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_ACCOUNT_ADMIN.role ||
        role.name === APPCONSTANTS.ROLES.EMR_OU_ADMIN.role
    );
    filteredRoles = filteredRoles.length
      ? filteredRoles
      : roles.filter(
          (role: any) =>
            role.name === APPCONSTANTS.ROLES.EMR_QUICKSIGHT_SPICE_ADMIN.role ||
            role.name === APPCONSTANTS.ROLES.EMR_QUICKSIGHT_TC_ADMIN.role
        );

    const userRole: roleType[] =
      filteredRoles?.length && Object.values(APPCONSTANTS.ROLES).filter(({ role }) => role === filteredRoles[0].name);
    if (
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_REPORT_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.SYSTEM_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_SITE_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_SITE_USER.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_REGION_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_ACCOUNT_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_OU_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_QUICKSIGHT_SPICE_ADMIN.role &&
      userRole[0]?.role !== APPCONSTANTS.ROLES.EMR_QUICKSIGHT_TC_ADMIN.role
    ) {
      info(APPCONSTANTS.ALERT, APPCONSTANTS.UNAUTHORIZED_ACCESS_MESSAGE);
      yield put(userActions.logoutRequest());
    }
    const payload: IUser = {
      username,
      firstName,
      lastName,
      id,
      role: userRole[0],
      tenantId,
      roles
    };
    yield put(userActions.fetchLoggedInUserSuccess(payload));
  } catch (e) {
    yield put(userActions.fetchLoggedInUserFail());
  }
}

export function* fetchDefaultRoles(): SagaIterator {
  try {
    const { data: roles } = yield call(userService.getDefaultRoles);
    yield put(userActions.fetchDefaultRoleSuccess(roles));
  } catch (e) {
    yield put(userActions.fetchDefaultRoleFailure());
  }
}

/*
  Worker Saga: Fired on FETCH_TERMS_CONDITIONS_REQUEST action
*/
export function* fetchTermsConditionsSaga({
  countryId,
  successCB,
  failureCB
}: IFetchTermsConditionsRequest): SagaIterator {
  try {
    const { data } = yield call(userService.fetchTermsConditionsAPI, countryId);
    const { entity: termsConditions } = data;
    successCB?.(termsConditions);
    yield put(userActions.fetchTermsAndConditionsSuccess(termsConditions));
  } catch (e) {
    yield put(userActions.fetchTermsAndConditionsFail(e));
  }
}

/*
  Worker Saga: Fired on UPDATE_TERMS_CONDITIONS_REQUEST action
*/
export function* updateTermsConditionsSaga({
  userId,
  isTermsAndConditionAccepted,
  successCB,
  failureCB
}: IUpdateTermsConditionsRequest): SagaIterator {
  try {
    yield call(userService.updateTermsConditionsAPI, { userId, isTermsAndConditionAccepted });
    successCB?.();
    yield put(userActions.updateTermsAndConditionsSuccess());
  } catch (e) {
    if (e instanceof Error) {
      failureCB?.(e);
    }
    yield put(userActions.updateTermsAndConditionsFail(e));
  }
}

/*
  Starts worker saga on latest dispatched `LOGIN_REQUEST` action.
  Allows concurrent increments.
*/
function* userSaga() {
  yield all([takeLatest(USERTYPES.LOGIN_REQUEST, login)]);
  yield all([takeLatest(USERTYPES.LOGOUT_REQUEST, logout)]);
  yield all([takeLatest(USERTYPES.USER_FORGOT_PASSWORD_REQUEST, userForgotPassword)]);
  yield all([takeLatest(USERTYPES.RESET_PASSWORD_REQUEST, resetPassword)]);
  yield all([takeLatest(USERTYPES.GET_USERNAME_FOR_PASSWORD_RESET, getUsername)]);
  yield all([takeLatest(USERTYPES.CREATE_PASSWORD_REQUEST, createPassword)]);
  yield all([takeLatest(USERTYPES.FETCH_TIMEZONE_LIST_REQUEST, fetchTimezoneList)]);
  yield takeLatest(USERTYPES.FETCH_LOGGED_IN_USER_REQUEST, fetchLoggedInUser);
  yield all([takeLatest(USERTYPES.FETCH_DEFAULT_ROLE, fetchDefaultRoles)]);
  yield all([takeLatest(USERTYPES.FETCH_TERMS_CONDITIONS_REQUEST, fetchTermsConditionsSaga)]);
  yield all([takeLatest(USERTYPES.UPDATE_TERMS_CONDITIONS_REQUEST, updateTermsConditionsSaga)]);
}

export default userSaga;
