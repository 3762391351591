import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { PROTECTED_ROUTES } from '../../constants/route';
import { initializingSelector } from '../../store/user/selectors';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import styles from './AppLayout.module.scss';

interface IAppLayout {
  children: string | React.ReactElement | React.ReactElement[];
}

const routesWithoutBreadcrumb = [
  PROTECTED_ROUTES.home
];


export const AppLayout = ({ children }: IAppLayout) => {
  const { pathname } = useLocation();

  const isBreadcrumbDisabled = useMemo(
    () => routesWithoutBreadcrumb.find((route) => matchPath(pathname, { path: route, exact: true })),
    [pathname]
  );

  const initializingApp = useSelector(initializingSelector);
  const isDashboardPage = Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.spiceQuickSightDashboard, exact: true })) || Boolean(matchPath(pathname, { path: PROTECTED_ROUTES.tcQuickSightDashboard, exact: true }));
  const dashboardStyle = isDashboardPage ? `${styles.dashboardLayout}` : `${styles.layout} justify-content-center`;

  return (
    <div
      className={`position-relative py-1dot875 px-3dot125 d-flex ${dashboardStyle}`}
    >
      {initializingApp ? null : (
        <div className={`px-md-3 px-1 ${styles.contentCenter}`}>
          {!isBreadcrumbDisabled &&
            <header className={`${styles.header} mb-1dot375 d-flex align-items-center`}>
              <Breadcrumb />
            </header>}
          <div>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLayout;
