export const PUBLIC_ROUTES = {
  login: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token'
};

export const PROTECTED_ROUTES = {
  regionDashboard: '/region',
  site: '/region/:regionId/:regionName/site',
  siteByRegion: '/region/:regionId/:regionName',
  reportList: '/region/:regionId/:regionName/site/:siteId/:siteName',
  MOHReport: '/region/:regionId/:regionName/site/:siteId/:siteName/MOH-report',
  permanentReport: '/region/:regionId/:regionName/site/:siteId/:siteName/permanent-report',
  patientStatusReport: '/region/:regionId/:regionName/site/:siteId/:siteName/patient-status-report',
  dailyReport: '/region/:regionId/:regionName/site/:siteId/:siteName/daily-report',
  fileDownload: '/region/:regionId/:regionName/fileDownload',
  screenedReport: '/region/:regionId/:regionName/site/:siteId/:siteName/screened-patient-report',
  enrolledPatientReport: '/region/:regionId/:regionName/site/:siteId/:siteName/enrolled-patient-report',
  userPerformance: '/region/:regionId/:regionName/site/:siteId/:siteName/user-performance',
  patientDuplicateReport: '/region/:regionId/:regionName/site/:siteId/:siteName/duplicate-profiles',
  reviewDuplicates: '/region/:regionId/:regionName/site/:siteId/:siteName/duplicate-profiles/:groupId',
  prescriptionReport: '/region/:regionId/:regionName/site/:siteId/:siteName/prescription-medication',
  manageUserRoles: '/manage-user-roles',
  assessmentReport: '/region/:regionId/:regionName/site/:siteId/:siteName/assessment-report',
  home: '/home',
  quickSightDashboard: '/dashboard',
  spiceQuickSightDashboard: '/dashboard/spice-quicksight',
  tcQuickSightDashboard: '/dashboard/telecounselling-quicksight'
};
