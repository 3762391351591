import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import {
  duplicatePatientSelector,
  loadingSelector,
  totalSelector,
} from '../../store/report/selectors';
import { fetchDuplicatePatientListRequest } from '../../store/report/actions';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import { PROTECTED_ROUTES } from '../../constants/route';
import sessionStorageServices from '../../global/sessionStorageServices';
import { firstNameSelector, lastNameSelector, roleSelector } from '../../store/user/selectors';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';

interface IMatchParams {
  regionId: string;
  regionName: string;
  siteId: string;
  siteName: string;
}

const DuplicateProfiles = (props: IMatchParams): React.ReactElement => {
  const [listParams, setListParams] = useState({
    page: APPCONSTANTS.NUMBER.ONE,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: '',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const DuplicatePatientList = useSelector(duplicatePatientSelector);
  const loading = useSelector(loadingSelector);
  const enrolledPatientCount = useSelector(totalSelector);

  const { regionId, regionName, siteId, siteName } = useParams<IMatchParams>();
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';


  const reqPayload = useCallback(
    (newParams: any) => {
      const reqData = {
        siteId: Number(siteId),
        skip:
          (newParams.page - APPCONSTANTS.NUMBER.ONE) * newParams.rowsPerPage,
        limit: newParams.rowsPerPage,
        failureCb: (e: Error) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(
              APPCONSTANTS.NETWORK_ERROR,
              APPCONSTANTS.CONNECTION_LOST
            );
          } else {
            toastCenter.error(
              APPCONSTANTS.OOPS,
              APPCONSTANTS.DUPLICATE_PATIENTS_FETCH_ERROR
            );
          }
        },
      };
      return reqData;
    },
    [siteId]
  );

  const fetchList = useCallback(
    (newParams: any) => {
      dispatch(
        fetchDuplicatePatientListRequest({ payload: reqPayload(newParams) })
      );
      setListParams(newParams);
    },
    [dispatch, reqPayload]
  );

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.REPORT_VIEW,
      APPCONSTANTS.REPORT_LIST.DUPLICATE_PROFILES,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );
    const newParams = {
      ...listParams,
      page: 1,
    };
    fetchList(newParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePage = (
    pageNo: number,
    rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE
  ) => {
    const newParams = {
      ...listParams,
      page: pageNo,
      rowsPerPage,
    };
    fetchList(newParams);
  };

  const cellStyleFormatter = (data: any) => {
    if (data.isUnsure) {
      return ' is-unsure';
    }
    return '';
  };

  const handleRowClick = (data: any) => {
    history.push({
      pathname: PROTECTED_ROUTES.reviewDuplicates
        .replace(':groupId', data.groupId)
        .replace(':regionId', regionId)
        .replace(':regionName', regionName)        
        .replace(':siteId', siteId)
        .replace(':siteName', siteName)
    });
    sessionStorageServices.setItem(APPCONSTANTS.IS_UNSURE_RECORD, data.isUnsure);
    sessionStorageServices.setItem(APPCONSTANTS.TOTAL_COUNT, data.profileCount);
  };

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col'>
          <DetailCard
            header='Duplicate Profiles'
            isSearch={false}
            isDisabled={DuplicatePatientList.length === 0}
          >
            <CustomTable
              rowData={DuplicatePatientList}
              columnsDef={[
                {
                  id: 1,
                  name: 'firstName',
                  label: 'First name',
                  cellStyleFormatter
                },
                {
                  id: 2,
                  name: 'lastName',
                  label: 'Last name',
                  cellStyleFormatter
                },
                {
                  id: 3,
                  name: 'age',
                  label: 'Age',
                  cellStyleFormatter
                },
                {
                  id: 4,
                  name: 'phoneNumber',
                  label: 'Phone Number',
                  cellStyleFormatter
                },
                {
                  id: 5,
                  name: 'profileCount',
                  label: 'No of Duplicate Profiles',
                  cellStyleFormatter
                },
              ]}
              isDelete={false}
              isEdit={false}
              isView={false}
              isDownload={false}
              page={listParams.page}
              handleRowClick={handleRowClick}
              rowsPerPage={listParams.rowsPerPage}
              count={enrolledPatientCount}
              handlePageChange={handlePage}
            />
          </DetailCard>
        </div>
      </div>
    </>
  );
};

export default DuplicateProfiles;
