import crypto from 'crypto';

import APPCONSTANTS from '../constants/appConstants';
import ReactGA from 'react-ga4';

const getEncryptionKey = () => {
  return crypto.pbkdf2Sync(
    process.env.REACT_APP_CRYPTR_SECRET_KEY as string,
    APPCONSTANTS.ENCRYPTION.SALT,
    APPCONSTANTS.ENCRYPTION.ITERATION,
    APPCONSTANTS.ENCRYPTION.KEYLEN,
    APPCONSTANTS.ENCRYPTION.DIGEST
  );
};

export const decryptData = (password: string) => {
  const key = getEncryptionKey();
  const decipher = crypto.createDecipheriv(
    APPCONSTANTS.ENCRYPTION.ALGM,
    key,
    APPCONSTANTS.ENCRYPTION.IV
  );
  return decipher.update(password, 'base64', 'utf8');
};

export const encryptData = (value: string) => {
  const key = getEncryptionKey();
  const cipher = crypto.createCipheriv(
    APPCONSTANTS.ENCRYPTION.ALGM,
    key,
    APPCONSTANTS.ENCRYPTION.IV
  );
  return cipher.update(value, 'utf8', 'base64') + cipher.final('base64');
};

/**
 * Appends zero before given number if number of digitd is less that minimumIntegerDigits
 * @param num
 * @param minimumIntegerDigits
 * @returns {string}
 */
export const appendZeroBefore = (
  num: number,
  minimumIntegerDigits: number
): string =>
  (Number(num) || 0).toLocaleString('en-US', {
    minimumIntegerDigits,
    useGrouping: false
  });

/**
 * A utility function to stop the event from propogating up on DOM
 * @param e
 */
export const stopPropogation = (e: React.BaseSyntheticEvent) => {
  try {
    e.stopPropagation();
  } catch (error) {
    console.error(error);
  }
};

/**
 * A utility function to check difference of two arrays
 */
export const differenceOfArray = (ary1: any, ary2: any) =>
  ary1.filter((value: any) => !ary2.includes(value));

/**
 * A utility function to convert camelCase to Sentence Case
 */
export const getSentenceCase = (camelCase: string) => {
  const result = camelCase.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const parseURLData = (url: string) => {
  const regex = /\/region\/(\d+)\/([^/]+)(\/site\/(\d+)\/([^/]+))?/;
  const matches = url.match(regex);

  if (!matches) {
      return {
          countryId: undefined,
          regionName: undefined,
          siteId: undefined,
          siteName: undefined,
      };
  }

  const countryId = matches[1] ? parseInt(matches[1]) : undefined;
  const regionName = matches[2];
  const siteId = matches[4] ? parseInt(matches[4]) : undefined;
  const siteName = matches[5];

  return {
      countryId: countryId?.toString(),
      regionName,
      siteId: siteId?.toString(),
      siteName,
  };
}

export const isUserAdmin = (role: any) => {
  if (role === APPCONSTANTS.ROLES.SYSTEM_ADMIN || role === APPCONSTANTS.ROLES.EMR_REPORT_ADMIN) {
    return true  
  }
  return false;
}
export const trackGoogleAnalyticsEvent = (category: string, event_name: string, route: string, data: any) => {
  const event_params = {
    category,
    route,
    ...data
  };
  ReactGA.event(event_name, event_params);
};

export const getPageLocationWithParams = () => {
  return window.location.pathname + window.location.search;
};
