import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { IAnyObject } from '../../components/customtable/CustomTable';
import SelectInput from '../../components/formFields/SelectInput';
import TextInput from '../../components/formFields/TextInput';

interface IPatientDetailsEditForm {
  profileOne: IAnyObject;
  profileTwo: IAnyObject;
  patient: IAnyObject;
  formFields: IAnyObject[];
  onPatientChange: (field: any, selected: any) => void;
}

const PatientDetailsEditForm = ({
  patient = {},
  profileOne = {},
  profileTwo = {},
  formFields = [],
  onPatientChange
}: IPatientDetailsEditForm) => {

  const formData: any = useMemo(() => {
    let data: any = {};
    // eslint-disable-next-line array-callback-return
    formFields.forEach((field: any) => {
      const options: any = [];
      if (profileOne[field.name]) {
        options.push({
          name: (field.other && profileOne[field.other]) ? profileOne[field.other] : profileOne[field.name],   // only for display purpose
          id: field.hasId ? profileOne[field.id] : profileOne[field.name],
          ...(field.other && { other: profileOne[field.other] })
        });
      }
      if (profileTwo[field.name]) {
        options.push({
          name: (field.other && profileTwo[field.other]) ? profileTwo[field.other] : profileTwo[field.name],  // only for display purpose
          id: field.hasId ? profileTwo[field.id] : profileTwo[field.name],
          ...(field.other && { other: profileTwo[field.other] })
        });
      }
      data[field.name] = options;
    })
    return data;
  }, [profileOne, profileTwo, formFields]);

  const getSelectedOption = (field: any) => {
    const options: any = formData[field.name] || [];
    return options.find((option: any) =>
       field.hasId
        ? option.id?.toString().trim() === patient[field.name]?.id.toString().trim()
        : option.id?.toString().trim() === patient[field.name]?.toString().trim()
      ) || ''
  }

  const isDisabledInput = useCallback((field: any) => {
    return (field.hasId ? (
      profileOne[field.id] === profileTwo[field.id]
      || !profileOne[field?.id]
      || !profileTwo[field?.id]
    ) : (
      (profileOne[field.name]?.toString().toLowerCase().trim()
        === profileTwo[field.name]?.toString().toLowerCase().trim())
      || !profileOne[field.name]?.toString().trim()
      || !profileTwo[field.name]?.toString().trim())
    )
      || !field.isEdit;
  }, [profileOne, profileTwo])

  return (
    <div>
      {formFields.map((field: IAnyObject, index: number) => (
        <div className='pb-1' key={`patient-edit-form-${field.name}`}>
          {isDisabledInput(field) ?
            <Field
              name={field.name}
              type='text'
              render={({ input, meta }) => (
                <TextInput
                  {...input}
                  {...meta}
                  required={false}
                  disabled={true}
                  label={field.label}
                  value={(field.hasId ? patient[field.name]?.name : patient[field.name]) || ''}
                  hideError
                />
              )}
            />
            :
            <Field
              name={field.name}
              type='text'
              render={({ input, meta }) => (
                <SelectInput
                  {...input}
                  {...meta}
                  required={false}
                  value={getSelectedOption(field)}
                  label={field.label}
                  options={formData[field.name] || []}
                  isShowLabel={true}
                  isFullWidth={true}
                  valueKey={'id'}
                  labelKey={'name'}
                  loadingOptions={false}
                  onChange={(seleted: any) => onPatientChange(field, seleted)}
                />
              )}
            />
          }
        </div>
      ))}
    </div>
  );
}

export default PatientDetailsEditForm;
