export const TOOLBAR_BUTTONS = [
  'source',
  '|',
  'bold',
  'strikethrough',
  'underline',
  'italic',
  '|',
  'ul',
  'ol',
  '|',
  'outdent',
  'indent',
  '|',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  '|',
  'image',
  'video',
  'table',
  'link',
  '|',
  'align',
  'undo',
  'redo',
  '|',
  'hr',
  'eraser',
  'copyformat',
  '|',
  'symbol',
  'fullsize',
  'print',
  'about'
];
