import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SpiceQuicksightLogo from '../../assets/images/Spice.svg';
import SpiceEngageQuicksightLogo from '../../assets/images/SpiceEngage.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import { rolesSelector } from '../../store/user/selectors';
import APPCONSTANTS from '../../constants/appConstants';

const Dashboard = (): React.ReactElement => {
  const roles = useSelector(rolesSelector);
  const rolesValue = useMemo(() => (roles || [])?.map((roleValue: any) => {
    return roleValue.name;
  }), [roles]);

  return (
    <div className='position-relative'>
      <div className='row'>
        {(rolesValue.includes(APPCONSTANTS.ROLES.EMR_QUICKSIGHT_SPICE_ADMIN.role))
          &&
          <div className='col-3 width-20'>
            <div className='cards quicksight-cards'>
              <Link to={PROTECTED_ROUTES.spiceQuickSightDashboard}>
                <div className='row p-2'>
                  <img src={SpiceQuicksightLogo} alt='insight-logo' width={60} height={70} />
                </div>
                <div className='row card-text p-1'>
                  <p>{APPCONSTANTS.QUICKSIGHT_DASHBOARD.SPICE.toUpperCase()}</p>
                </div>
              </Link>
            </div>
          </div>
        }
        {(rolesValue.includes(APPCONSTANTS.ROLES.EMR_QUICKSIGHT_TC_ADMIN.role))
          &&
          <div className='col-3 width-20'>
            <div className='cards quicksight-cards'>
              <Link to={PROTECTED_ROUTES.tcQuickSightDashboard}>
                <div className='row p-2'>
                  <img src={SpiceEngageQuicksightLogo} alt='insight-logo' width={60} height={70} />
                </div>
                <div className='row card-text p-1'>
                  <p>{APPCONSTANTS.QUICKSIGHT_DASHBOARD.SPICE_ENGAGE.toUpperCase()}</p>
                </div>
              </Link>
            </div>
          </div>
        }        
      </div>
    </div>
  );
};

export default Dashboard;
