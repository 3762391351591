import React from 'react';
import styles from './TextInput.module.scss';

interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  errorLabel?: string;
  showLoader?: boolean;
  hideError?: boolean;
}

const TextInput = ({
  label,
  error = '',
  errorLabel = '',
  required = true,
  showLoader = false,
  hideError = false,
  ...props
}: ITextInputProps) => {
  return (
    <div className={`${styles.textInput} ${error ? styles.danger : ''}`}>
      <label htmlFor={props.name}>
        {label}
        {required && <span className='input-asterisk'>*</span>}
      </label>
      <br />
      <input {...props} />
      {showLoader && (
        <div className={styles.iconContainer}>
          <i className={styles.loader} />
        </div>
      )}
      {!hideError && (
        <div id='error' className={styles.error}>
          {error} {error && errorLabel}
        </div>
      )}
    </div>
  );
};

export default TextInput;
