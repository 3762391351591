import axios from 'axios';
import { saveAs } from 'file-saver';

export const fileDownload = async (siteName: string, type: string, reportDate: any, reportData: []) => {
  const fileName = siteName + type + reportDate;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const blob = new Blob([new Uint8Array(reportData)], { type: fileType });
  return saveAs(blob, fileName + fileExtension, { autoBom: false });
};

export const fetchReportList = (data: { key: string; reportType: string; siteId: number; limit: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/list',
    data
  });

export const isMOHReportPending = (data: { key: string; report_type: string; facilityId: string }) =>
  axios({
    method: 'POST',
    url: 'spice-connect/reports/status',
    data
  });

export const sendMOHReportToKHIS = (data: {
  month: string;
  year: number;
  siteId: number;
  username: string;
  password: string;
}) =>
  axios({
    method: 'POST',
    url: 'spice-connect/reports/send-to-KHIS',
    data
  });

export const fetchMOHReport = (data: { month: string; year: string; siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/MOH',
    data
  });

export const fetchPermanentReport = (data: { month: string; year: string; siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/permanent-register',
    data
  });

export const fetchPatientStatusReport = (data: { month: string; year: string; siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/patient-status-report',
    data
  });  

export const fetchDailyReport = (data: { date: string; siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/daily-register',
    data
  });

export const fetchScreenedReport = (data: { siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/screened-patient-list',
    data
  });

export const downloadScreenedPatientsReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/screened-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Screened_Patients';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const fetchEnrolledPatientReport = (data: { siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/enrolled-patient-list',
    data
  });

export const fetchDuplicatePatientReport = (data: { siteId: number; limit: number | null }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/duplicate-profile/list',
    data
  });

export const fetchAssessmentListReport = (data: { siteId: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/assessed-patient-list',
    data
  });

export const downloadEnrolledPatientsReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/enrolled-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Enrolled_Patients';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const downloadAssessmentListReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/assessed-patients-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_Assessment_Report';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const downloadUserPerformanceReport = (data: { siteName: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/user-performance-download',
    data,
    responseType: 'blob'
  }).then((response) => {
    const filename = data.siteName.replace(/\./g, ' ') + '_User_Performance';
    saveAs(
      new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      filename,
      { autoBom: false }
    );
  });

export const fetchPrescriptionList = (data: { tenantId: number; year?: number; month?: number; limit: number }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/prescription-report-list',
    data
  });

export const fetchPrescriptionReport = (data: { tenantId: number; fromDate: string; toDate: string }) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/prescription-list',
    data
  });

export const fetchUserPerformanceReport = (data: {
  tenantId: number;
  roles?: string[];
  fromDate?: string;
  toDate?: string;
  skip?: number;
  limit: number | null;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/report/user-performance-report',
    data
  });
