import arrayMutators from 'final-form-arrays';
import ModalForm from '../../components/modal/ModalForm';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  countryIdSelector,
  isTACLoadingSelector,
  roleSelector,
  termsAndConditionsSelector,
  userIdSelector
} from '../../store/user/selectors';
import Loader from '../../components/loader/Loader';
import { ITermsAndConditions } from '../../store/user/types';
import { fetchTermsAndConditionsRequest, updateTermsAndConditionsRequest } from '../../store/user/actions';
import TextEditor, { ITextEditorConfigs } from '../../components/editor/WysiwygEditor';
import './TermsAndConditions.scss';
import toastCenter, { getErrorToastArgs } from '../../utils/toastCenter';
import APPCONSTANTS from '../../constants/appConstants';
import localStorageService from '../../global/localStorageServices';

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(roleSelector);
  const countryId = useSelector(countryIdSelector);
  const userId = useSelector(userIdSelector);
  const [showModal, setShowModal] = useState(true);
  const termsAndConditionsData = useSelector(termsAndConditionsSelector);
  const isTACLoading = useSelector(isTACLoadingSelector);
  const { formInput: tacData, countryId: termsCountryId } = termsAndConditionsData as ITermsAndConditions;

  const getTACDismissed = useCallback(() => {
    return JSON.parse(localStorageService.getItem(APPCONSTANTS.IS_TERMS_CONDITIONS_DISMISSED));
  }, []);
  const getTACDStatus = useCallback(() => {
    return JSON.parse(localStorageService.getItem(APPCONSTANTS.TAC_STATUS));
  }, []);

  const {
    SUPER_ADMIN: { role: SUPER_ADMIN },
    SUPER_USER: { role: SUPER_USER }
  } = APPCONSTANTS.ROLES;

  const showTACCondition =
    !getTACDismissed() && !getTACDStatus() && ![SUPER_USER, SUPER_ADMIN].includes((userRole as any)?.role || userRole);

  useEffect(() => {
    if (
      ((!termsAndConditionsData?.formInput && countryId) || countryId !== termsCountryId || !termsCountryId) &&
      showTACCondition
    ) {
      dispatch(
        fetchTermsAndConditionsRequest({
          countryId: Number(countryId),
          successCB: (data) => {
            if (!data.formInput) {
              setShowModal(false);
              localStorageService.setItem(APPCONSTANTS.IS_TERMS_CONDITIONS_DISMISSED, true);
            }
          },
          failureCB: () => {
            setShowModal(false);
            localStorageService.setItem(APPCONSTANTS.IS_TERMS_CONDITIONS_DISMISSED, true);
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, dispatch, getTACDStatus, getTACDismissed, userRole, showTACCondition]);

  const basicEditorConfig = {
    toolbarSticky: false,
    editHTMLDocumentMode: false,
    showXPathInStatusbar: false
  };

  const initialEditorConfig: ITextEditorConfigs = {
    disabled: false,
    readonly: true,
    buttons: undefined
  };
  delete initialEditorConfig.height;

  const editModalRender = () => {
    return (
      <div className='tacDiv'>
        <TextEditor
          editorConfig={initialEditorConfig}
          editorContent={tacData}
          basicConfig={basicEditorConfig}
          removeCustomHeight={true}
        />
      </div>
    );
  };

  const handleSubmit = () => {
    dispatch(
      updateTermsAndConditionsRequest({
        userId: Number(userId),
        isTermsAndConditionAccepted: true,
        successCB: () => {
          setShowModal(false);
          localStorageService.setItem(APPCONSTANTS.TAC_STATUS, true);
        },
        failureCB: (e) => {
          toastCenter.error(...getErrorToastArgs(e, APPCONSTANTS.ERROR, APPCONSTANTS.TERMSCONDITIONS_UPDATE_FAIL));
        }
      })
    );
  };

  const handleDismiss = () => {
    localStorageService.setItem(APPCONSTANTS.IS_TERMS_CONDITIONS_DISMISSED, true);
    setShowModal(false);
  };

  return showTACCondition ? (
    <>
      {isTACLoading && <Loader />}
      {tacData && (
        <ModalForm
          show={showModal}
          title={`Terms and Conditions`}
          cancelText='Decline'
          submitText='Accept'
          isScrollNeeded={true}
          handleCancel={handleDismiss}
          handleFormSubmit={handleSubmit}
          mutators={arrayMutators}
          render={editModalRender}
          showCloseBtn={false}
          size='modal-lg'
          autoHeight={true}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default TermsAndConditions;
