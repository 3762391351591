import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getSpiceDashboardURL } from '../../services/regionAPI';
import APPCONSTANTS from '../../constants/appConstants';
import Loader from '../../components/loader/Loader';
import { firstNameSelector, lastNameSelector, roleSelector, usernameSelector } from '../../store/user/selectors';
import toastCenter from '../../utils/toastCenter';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import './Dashboard.scss';
import { getPageLocationWithParams, trackGoogleAnalyticsEvent } from '../../utils/commonUtils';


/**
 * Lists all the regions
 * Provides search feature
 * Provided the links to navigate to creation page of super admin and region
 * @returns {React.ReactElement}
 */
const SpiceQuicksightDashboard = (): React.ReactElement => {
  const containerDiv = useRef(null);
  const [hideIframe, setHideIframe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quickSightURL, setQuickSightURL] = useState(null);
  const username = useSelector(usernameSelector);
  const role: any = useSelector(roleSelector);
  const firstName: string = useSelector(firstNameSelector) || '';
  const lastName: string = useSelector(lastNameSelector) || '';
  const loadDashboard = useCallback((url: string) => {
    const options = {
      url: quickSightURL,
      container: containerDiv.current,
      iframeResizeOnSheetChange: false,
      printEnabled: true,
      footerPaddingEnabled: true,
      sheetTabsDisabled: false,
      undoRedoDisabled: false,
      resetDisabled: false,
      errorCallback: () => {
        setHideIframe(false);
      }
    };
    QuickSightEmbedding.embedDashboard(options);

  }, [quickSightURL]);

  useEffect(() => {
    trackGoogleAnalyticsEvent(
      APPCONSTANTS.USER_ACTIONS.QUICKSIGHT_DASHBOARD,
      APPCONSTANTS.REPORT_LIST.SPICE_QUICKSIGHT_DASHBOARD,
      getPageLocationWithParams(),
      { username: `${firstName} ${lastName}`, role: role.label }
    );    
    getSpiceDashboardURL({ username }).then(res => {
      setQuickSightURL(res.data.entity.embedUrl);
    }).catch((err) => {
      setLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.DASHBOARD_FAIL_MSG);
    }).finally(() => setLoading(false));
  }, [firstName, lastName, role.label, username]);

  useEffect(() => {
    if (quickSightURL) {
      loadDashboard(quickSightURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSightURL]);


  return (
    <>
      {loading && <Loader />}
      <div className={`position-relative`}>
        {!hideIframe &&
          <div className='iframeContainer' ref={containerDiv} />
        }
      </div>
    </>
  );
};

export default SpiceQuicksightDashboard;
