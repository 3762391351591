import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StylesConfig } from 'react-select';
import MulitiSelect, {
  IOption
} from '../../components/mulitiSelect/MultiSelect';
import APPCONSTANTS from '../../constants/appConstants';
import { fetchSiteAdminSitesRequest } from '../../store/userRoles/actions';
import {
  getSiteAdminSitesSelector,
  getUserRolesLoadingSelector
} from '../../store/userRoles/selectors';
import {
  ISiteAdminSites,
  IUserRolesList
} from '../../store/userRoles/types';
import toastCenter from '../../utils/toastCenter';

interface IProps {
  multiSelectRef: any;
  initiallySelectedRef: any;
  selectedValue: IOption[];
  setSelectedValue: Dispatch<SetStateAction<IOption[]>>;
  assignUserRole: { open: boolean; selectedUser: IUserRolesList };
  isEmrUserSiteAdmin?: boolean;
  isDefaultSiteRemoved: boolean;
  setDefaultSiteRemoved: Dispatch<SetStateAction<boolean>>;
}

const RenderSiteAdminRoleModal = ({
  multiSelectRef,
  initiallySelectedRef,
  selectedValue,
  setSelectedValue,
  assignUserRole,
  isEmrUserSiteAdmin = false,
  isDefaultSiteRemoved,
  setDefaultSiteRemoved
}: IProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(getUserRolesLoadingSelector);
  const siteAdminSites = useSelector(getSiteAdminSitesSelector);
  const [isSiteRemove, setSiteRemove] = useState(false);
  const [sitesRemovedMessage, setSitesRemovedMessage] = useState('');

  const formatAsOptionList = (data: any) => {
    return data.map((site: ISiteAdminSites) => ({
      value: site?.value,
      label: site?.label,
      disabled: site?.is_default,
      assigned: site?.is_assigned
    }));
  };

  const formatSiteAdminSites = (isOptionList: boolean, payload?: any) => {
    let data: any = [];
    if (isOptionList) {
      data = siteAdminSites;
      return formatAsOptionList(data).sort(
        (a: IOption, b: IOption) => Number(b.disabled) - Number(a.disabled)
      );
    } else {
      let defaultSite: IOption | null = null;
      payload.forEach((site: ISiteAdminSites) => {
        if ((site?.is_default && site?.is_assigned) || site?.is_assigned) {
          data?.push(site);
        } else if (site?.is_default && !site?.is_assigned) {
          defaultSite = {
            value: site?.value,
            label: site?.label,
            disabled: site?.is_default,
            assigned: site?.is_assigned
          };
        }
      });
      const initiallySelectedList = formatAsOptionList(data);
      initiallySelectedRef.current = [...initiallySelectedList];
      if (defaultSite !== null) {
        initiallySelectedList.push(defaultSite);
      }
      return initiallySelectedList.sort(
        (a: IOption, b: IOption) => Number(b.disabled) - Number(a.disabled)
      );
    }
  };

  const customMultiSelectStyles: StylesConfig<any> = {
    multiValue: (base, state) => {
      return base;
    },
    multiValueLabel: (base, state) => {
      return state.data.disabled
        ? {
          ...base,
          background: '#6165DE',
          fontWeight: '500',
          color: 'white',
          '::after': { content: '" (Primary Site) "' }
        }
        : base;
    },
    multiValueRemove: (base, state) => {
      const currentSelectedValue = multiSelectRef.current?.getValue();
      if (currentSelectedValue?.length === 1 && state.data.disabled) {
        if (state?.data?.assigned) {
          return {
            ...base,
            background: '#6165DE',
            fontWeight: '500',
            color: 'white',
            marginLeft: '-2px',
            display: 'block !important'
          };
        } else {
          return { ...base, display: 'none !important' };
        }
      } else if (currentSelectedValue?.length > 1 && state.data.disabled) {
        return { ...base, display: 'none !important' };
      } else {
        return base;
      }
    }
  };

  const switchCase = (actionMeta: any, filterValue: any, newValue: any, primarySite: any) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (
          (filterValue?.length > 1 && actionMeta.removedValue?.disabled) ||
          (filterValue?.length === 1 &&
            actionMeta.removedValue?.disabled &&
            !actionMeta.removedValue?.assigned)
        ) {
          return;
        }
        break;
      case 'deselect-option':
        if (
          (filterValue?.length > 1 && actionMeta?.option?.disabled) ||
          (filterValue?.length === 1 &&
            actionMeta.option?.disabled &&
            !actionMeta.option?.assigned)
        ) {
          return;
        }
        break;
      case 'create-option':
      case 'select-option':
        if (filterValue?.length === 0) {
          newValue = [primarySite].concat(newValue);
          return newValue;
        }
        break;
      case 'clear':
        newValue = newValue.filter((item: any) => item.disabled);
        return newValue;
    }
  };

  const customMultiSelectOnChange = (params: any) => {
    const { newValue } = params;
    const {
      actionMeta,
      setShowError,
      filterValue,
      handleFilterChange,
      setFilteredValue,
    } = params;
    const difference = (
      newValue.length > filterValue.length ? newValue : filterValue
    ).filter((object1: any) => {
      return !(
        newValue.length > filterValue.length ? filterValue : newValue
      ).some((object2: any) => {
        return object1 === object2;
      });
    });
    const primarySite = multiSelectRef?.current?.props?.options.find(
      (item: any) => item?.disabled
    );
    if (primarySite?.assigned) {
      if (!newValue.length) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
    difference[0]?.assigned === true &&
    (actionMeta.action === 'remove-value' ||
      actionMeta.action === 'deselect-option')
      ? setSiteRemove(true)
      : setSiteRemove(false);
    switchCase(actionMeta, filterValue, newValue, primarySite);
    handleFilterChange?.(newValue);
    setFilteredValue(newValue);
    if (params.newValue.length === 0){
      setSitesRemovedMessage(
        "All sites have been removed, User can't access reporting portal."
      );
    } else {
      setSitesRemovedMessage('');
    }
  };

  useEffect(() => {
    if (!loading) {
      dispatch(
        fetchSiteAdminSitesRequest({
          id: Number(assignUserRole?.selectedUser?.id),
          successCb: (payload: any) => {
            setSelectedValue(formatSiteAdminSites(false, payload));
          },
          failureCb: (e) => {
            toastCenter.error(
              APPCONSTANTS.OOPS,
              APPCONSTANTS.SITE_ADMIN_SITES_FETCH_ERROR
            );
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }
  return (
    <>
      <p>Select the preferred sites for this user's access:</p>
      {(isDefaultSiteRemoved || isSiteRemove) && (
        <div
          className='alert alert-warning d-flex align-items-center p-dot5'
          role='alert'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='currentColor'
            className='bi bi-exclamation-triangle-fill flex-shrink-0 me-dot625'
            viewBox='0 0 16 16'
            role='img'
            aria-label='Warning:'
          >
            <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
          </svg>
          {isDefaultSiteRemoved && (
            <div>
              All sites have been removed, User can't access reporting and spice
              portal.
            </div>
          )}
          {isEmrUserSiteAdmin && selectedValue.length === 0 && (
            <div>
              {sitesRemovedMessage}
            </div>
          )}
          {!isDefaultSiteRemoved &&
            isSiteRemove &&
            selectedValue.length !== 0 && (
              <div>
                {!isEmrUserSiteAdmin
                  ? "If this site gets removed, User can't access this site in reporting and spice portal."
                  : "If this site gets removed, User can't access this site in reporting portal."}
              </div>
            )}
        </div>
      )}
      <MulitiSelect
        multiSelectRef={multiSelectRef}
        loading={loading}
        selectedValue={selectedValue}
        deselectFlag={() => setSiteRemove(true)}
        options={formatSiteAdminSites(true)}
        handleFilterChange={(value) => setSelectedValue(value)}
        isCustomValueEnabled={false}
        isCustomDropdownEnabled={false}
        isApplyButtonNeeded={false}
        customStyles={customMultiSelectStyles}
        customOnChange={customMultiSelectOnChange}
        setShowError={setDefaultSiteRemoved}
      />
    </>
  );
};

export default RenderSiteAdminRoleModal;
